import React from "react";
import { Outlet, Route } from "react-router-dom";
import SensorsMapProxy from "../proxies/SensorsMapProxy";
import SensorsSensorsProxy from "../proxies/SensorsSensorsProxy";
import SensorsSensorsItemProxy from "../proxies/SensorsSensorsItemProxy";
import { Home } from "../pages/Home";
import { Administration } from "../pages/Administration";
import { SensorCollection } from "../pages/SensorCollection";
import { SensorView } from "../pages/SensorView";

const routes = (
  <Route element={<SensorsMapProxy />}>
    <Route path="sensors" element={<Outlet />}>
      <Route index element={<Home />} />
      <Route path="admin" element={<Administration />} />
      <Route path="sensor" element={<Outlet />}>
        <Route element={<SensorsSensorsProxy />}>
          <Route index element={<SensorCollection />} />
          <Route element={<SensorsSensorsItemProxy />}>
            <Route path=":id" element={<SensorView />} />
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
);

export default routes;
