import React from "react";
import type { MapLayer } from "@smartly-city/compo-map";
import type {
  Sensors_SensorJournalRowModel,
  Sensors_SensorRowModel,
} from "src/graphql/types";
import { daysBetween } from "src/core/utils/date";

export const SENSOR_LAYER_ID = "sensor";

export const useSensorLayerBuilder = (): ((
  sensors: Sensors_SensorRowModel[],
  journals: Sensors_SensorJournalRowModel[]
) => MapLayer) => {
  return React.useCallback(
    (sensors, journals) => ({
      id: SENSOR_LAYER_ID,
      color: "#767676",
      items: sensors.map((sensor) => ({
        id: sensor.id,
        coordinates: sensor.location,
        name: sensor.name,
        color: getSensorColor(journals.find((j) => j.sensorId === sensor.id)),
      })),
    }),
    []
  );
};

const getSensorColor = (journal?: Sensors_SensorJournalRowModel): string => {
  const communicatedAgo = journal ? getCommunicatedDays(journal) : null;
  switch (true) {
    case communicatedAgo && communicatedAgo < 3:
      return "#3CB56C";
    case communicatedAgo && communicatedAgo < 7:
      return "#F9E850";
    case communicatedAgo && communicatedAgo < 14:
      return "#FFB03A";
    case communicatedAgo && communicatedAgo < 30:
      return "#FF3A3A";
    default:
      return "#767676";
  }
};

const getCommunicatedDays = (
  journal: Sensors_SensorJournalRowModel
): number | undefined => {
  return journal.status.communicationDate
    ? daysBetween(new Date(journal.status.communicationDate), new Date())
    : undefined;
};
