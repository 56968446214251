import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";

const SensorListSkeleton: React.FC = () => {
  return (
    <Wrapper>
      <RowsWrapper>
        {Array(10)
          .fill(0)
          .map((_, index) => (
            <Compo.ListRowSkeleton key={index} wide button />
          ))}
      </RowsWrapper>
      <PaginationWrapper>
        <Compo.PaginationSkeleton wide />
      </PaginationWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  overflow: hidden;
`;

const RowsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`;

const PaginationWrapper = styled.div`
  margin-top: auto;
  z-index: 1;
  background-color: #ffffff;
  padding-top: 1rem;
`;

export default SensorListSkeleton;
