import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SensorByIdFromSensorsQueryVariables = Types.Exact<{
  input: Types.Sensors_GetSensorByIdInput;
}>;


export type SensorByIdFromSensorsQuery = { __typename?: 'Query', Sensors_sensorById: { __typename?: 'Sensors_GetSensorByIdPayload', sensor?: { __typename?: 'Sensors_SensorModel', id: any, version: number, created: any, changed: any, areaId: any, type: Types.Sensors_SensorTypeModel, name: string, smartlyId: string, location: { __typename?: 'Sensors_CoordinatesModel', latitude: number, longitude: number, hash?: string | null }, units?: { __typename?: 'Sensors_SensorUnitsModel', distanceUnit?: Types.Sensors_DistanceUnitModel | null } | null, boundaries?: { __typename?: 'Sensors_SensorBoundariesModel', minValue: number, maxValue: number } | null } | null } };


export const SensorByIdFromSensorsDocument = gql`
    query sensorByIdFromSensors($input: Sensors_GetSensorByIdInput!) {
  Sensors_sensorById(input: $input) {
    sensor {
      id
      version
      created
      changed
      areaId
      type
      name
      location {
        latitude
        longitude
        hash
      }
      smartlyId
      units {
        distanceUnit
      }
      boundaries {
        minValue
        maxValue
      }
    }
  }
}
    `;

/**
 * __useSensorByIdFromSensorsQuery__
 *
 * To run a query within a React component, call `useSensorByIdFromSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorByIdFromSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorByIdFromSensorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSensorByIdFromSensorsQuery(baseOptions: Apollo.QueryHookOptions<SensorByIdFromSensorsQuery, SensorByIdFromSensorsQueryVariables> & ({ variables: SensorByIdFromSensorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorByIdFromSensorsQuery, SensorByIdFromSensorsQueryVariables>(SensorByIdFromSensorsDocument, options);
      }
export function useSensorByIdFromSensorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorByIdFromSensorsQuery, SensorByIdFromSensorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorByIdFromSensorsQuery, SensorByIdFromSensorsQueryVariables>(SensorByIdFromSensorsDocument, options);
        }
export function useSensorByIdFromSensorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SensorByIdFromSensorsQuery, SensorByIdFromSensorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SensorByIdFromSensorsQuery, SensorByIdFromSensorsQueryVariables>(SensorByIdFromSensorsDocument, options);
        }
export type SensorByIdFromSensorsQueryHookResult = ReturnType<typeof useSensorByIdFromSensorsQuery>;
export type SensorByIdFromSensorsLazyQueryHookResult = ReturnType<typeof useSensorByIdFromSensorsLazyQuery>;
export type SensorByIdFromSensorsSuspenseQueryHookResult = ReturnType<typeof useSensorByIdFromSensorsSuspenseQuery>;
export type SensorByIdFromSensorsQueryResult = Apollo.QueryResult<SensorByIdFromSensorsQuery, SensorByIdFromSensorsQueryVariables>;