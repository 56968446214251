import { useTranslation } from "react-i18next";
import type {
  UseTranslationOptions,
  UseTranslationResponse,
} from "react-i18next";

export const useSensorsTranslation = (
  options?: UseTranslationOptions<undefined>
): UseTranslationResponse<"sensors"> => {
  return useTranslation("sensors", options);
};
