import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SensorsPagedFromSensorsQueryVariables = Types.Exact<{
  input: Types.Sensors_GetSensorsPagedInput;
}>;


export type SensorsPagedFromSensorsQuery = { __typename?: 'Query', Sensors_sensorsPaged: { __typename?: 'Sensors_GetSensorsPagedPayload', page: number, count: number, total: number, items: Array<{ __typename?: 'Sensors_SensorRowModel', id: any, areaId: any, type: Types.Sensors_SensorTypeModel, name: string, location: { __typename?: 'Sensors_CoordinatesModel', latitude: number, longitude: number, hash?: string | null } }> } };


export const SensorsPagedFromSensorsDocument = gql`
    query sensorsPagedFromSensors($input: Sensors_GetSensorsPagedInput!) {
  Sensors_sensorsPaged(input: $input) {
    page
    count
    total
    items {
      id
      areaId
      type
      name
      location {
        latitude
        longitude
        hash
      }
    }
  }
}
    `;

/**
 * __useSensorsPagedFromSensorsQuery__
 *
 * To run a query within a React component, call `useSensorsPagedFromSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorsPagedFromSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorsPagedFromSensorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSensorsPagedFromSensorsQuery(baseOptions: Apollo.QueryHookOptions<SensorsPagedFromSensorsQuery, SensorsPagedFromSensorsQueryVariables> & ({ variables: SensorsPagedFromSensorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorsPagedFromSensorsQuery, SensorsPagedFromSensorsQueryVariables>(SensorsPagedFromSensorsDocument, options);
      }
export function useSensorsPagedFromSensorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorsPagedFromSensorsQuery, SensorsPagedFromSensorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorsPagedFromSensorsQuery, SensorsPagedFromSensorsQueryVariables>(SensorsPagedFromSensorsDocument, options);
        }
export function useSensorsPagedFromSensorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SensorsPagedFromSensorsQuery, SensorsPagedFromSensorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SensorsPagedFromSensorsQuery, SensorsPagedFromSensorsQueryVariables>(SensorsPagedFromSensorsDocument, options);
        }
export type SensorsPagedFromSensorsQueryHookResult = ReturnType<typeof useSensorsPagedFromSensorsQuery>;
export type SensorsPagedFromSensorsLazyQueryHookResult = ReturnType<typeof useSensorsPagedFromSensorsLazyQuery>;
export type SensorsPagedFromSensorsSuspenseQueryHookResult = ReturnType<typeof useSensorsPagedFromSensorsSuspenseQuery>;
export type SensorsPagedFromSensorsQueryResult = Apollo.QueryResult<SensorsPagedFromSensorsQuery, SensorsPagedFromSensorsQueryVariables>;