import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndexSensorJournalsFromSensorsMutationVariables = Types.Exact<{
  input: Types.Sensors_Admin_IndexSensorJournalsInput;
}>;


export type IndexSensorJournalsFromSensorsMutation = { __typename?: 'Mutation', Sensors_Admin_indexSensorJournals: { __typename?: 'Sensors_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Sensors_GraphQLError', key: string, message: string, variables: Array<{ __typename?: 'Sensors_KeyValuePairOfStringAndString', key: string, value: string }> }> } };


export const IndexSensorJournalsFromSensorsDocument = gql`
    mutation indexSensorJournalsFromSensors($input: Sensors_Admin_IndexSensorJournalsInput!) {
  Sensors_Admin_indexSensorJournals(input: $input) {
    isSuccess
    errors {
      key
      message
      variables {
        key
        value
      }
    }
    created
  }
}
    `;
export type IndexSensorJournalsFromSensorsMutationFn = Apollo.MutationFunction<IndexSensorJournalsFromSensorsMutation, IndexSensorJournalsFromSensorsMutationVariables>;

/**
 * __useIndexSensorJournalsFromSensorsMutation__
 *
 * To run a mutation, you first call `useIndexSensorJournalsFromSensorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndexSensorJournalsFromSensorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indexSensorJournalsFromSensorsMutation, { data, loading, error }] = useIndexSensorJournalsFromSensorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIndexSensorJournalsFromSensorsMutation(baseOptions?: Apollo.MutationHookOptions<IndexSensorJournalsFromSensorsMutation, IndexSensorJournalsFromSensorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndexSensorJournalsFromSensorsMutation, IndexSensorJournalsFromSensorsMutationVariables>(IndexSensorJournalsFromSensorsDocument, options);
      }
export type IndexSensorJournalsFromSensorsMutationHookResult = ReturnType<typeof useIndexSensorJournalsFromSensorsMutation>;
export type IndexSensorJournalsFromSensorsMutationResult = Apollo.MutationResult<IndexSensorJournalsFromSensorsMutation>;
export type IndexSensorJournalsFromSensorsMutationOptions = Apollo.BaseMutationOptions<IndexSensorJournalsFromSensorsMutation, IndexSensorJournalsFromSensorsMutationVariables>;