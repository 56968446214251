import { dateStringAsDateTimeString } from "src/core/utils/date";
import type { SensorFilters } from "../contexts/SensorFilters";
import type { Sensors_SensorFiltersModelInput } from "src/graphql/types";

export const toSensorQueryFilters = (
  filters?: SensorFilters
): Sensors_SensorFiltersModelInput | null => {
  if (!filters) return null;

  return {
    matchesName: filters.name ?? null,
    ofType: filters.type ?? null,
    matchesSmartlyId: filters.smartlyId ?? null,
    ofDistanceUnit: filters.distanceUnit ?? null,
    usingJournal: {
      minCommunicationDate:
        dateStringAsDateTimeString(
          filters.communicationDate?.min?.toLocaleDateString(),
          "min"
        ) ?? null,
      maxCommunicationDate:
        dateStringAsDateTimeString(
          filters.communicationDate?.max?.toLocaleDateString(),
          "max"
        ) ?? null,
      minReadingsDate:
        dateStringAsDateTimeString(
          filters.readingsDate?.min?.toLocaleDateString(),
          "min"
        ) ?? null,
      maxReadingsDate:
        dateStringAsDateTimeString(
          filters.readingsDate?.max?.toLocaleDateString(),
          "max"
        ) ?? null,
      minSignalStrength: filters.signalStrength?.min ?? null,
      maxSignalStrength: filters.signalStrength?.max ?? null,
      minSignalToNoiseRatio: filters.signalToNoiseRatio?.min ?? null,
      maxSignalToNoiseRatio: filters.signalToNoiseRatio?.max ?? null,
      minBatteryLevel: filters.batteryLevel?.min ?? null,
      maxBatteryLevel: filters.batteryLevel?.max ?? null,
      minDistance: filters.distance?.min ?? null,
      maxDistance: filters.distance?.max ?? null,
      minDistancePercent: filters.distancePercent?.min ?? null,
      maxDistancePercent: filters.distancePercent?.max ?? null,
    },
  };
};
