import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveSensorFromSensorsMutationVariables = Types.Exact<{
  input: Types.Sensors_RemoveSensorInput;
}>;


export type RemoveSensorFromSensorsMutation = { __typename?: 'Mutation', Sensors_removeSensor: { __typename?: 'Sensors_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Sensors_GraphQLError', key: string, message: string, variables: Array<{ __typename?: 'Sensors_KeyValuePairOfStringAndString', key: string, value: string }> }> } };


export const RemoveSensorFromSensorsDocument = gql`
    mutation removeSensorFromSensors($input: Sensors_RemoveSensorInput!) {
  Sensors_removeSensor(input: $input) {
    isSuccess
    errors {
      key
      message
      variables {
        key
        value
      }
    }
    created
  }
}
    `;
export type RemoveSensorFromSensorsMutationFn = Apollo.MutationFunction<RemoveSensorFromSensorsMutation, RemoveSensorFromSensorsMutationVariables>;

/**
 * __useRemoveSensorFromSensorsMutation__
 *
 * To run a mutation, you first call `useRemoveSensorFromSensorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSensorFromSensorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSensorFromSensorsMutation, { data, loading, error }] = useRemoveSensorFromSensorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSensorFromSensorsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSensorFromSensorsMutation, RemoveSensorFromSensorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSensorFromSensorsMutation, RemoveSensorFromSensorsMutationVariables>(RemoveSensorFromSensorsDocument, options);
      }
export type RemoveSensorFromSensorsMutationHookResult = ReturnType<typeof useRemoveSensorFromSensorsMutation>;
export type RemoveSensorFromSensorsMutationResult = Apollo.MutationResult<RemoveSensorFromSensorsMutation>;
export type RemoveSensorFromSensorsMutationOptions = Apollo.BaseMutationOptions<RemoveSensorFromSensorsMutation, RemoveSensorFromSensorsMutationVariables>;