import React from "react";
import MapProxy from "src/core/proxies/MapProxy";
import { useNavigate } from "react-router-dom";
import { useCurrentArea } from "src/core/contexts";
import { useSensorLayerBuilder } from "../utils/layers";
import { useSensorsFromSensorsSuspenseQuery } from "src/graphql/__generated__/SensorsSensors";
import { useSensorJournalsFromSensorsSuspenseQuery } from "src/graphql/__generated__/SensorsSensorJournals";
import type { MapItem } from "@smartly-city/compo-map";

const SensorsMapProxy: React.FC = () => {
  const area = useCurrentArea();
  const navigate = useNavigate();
  const sensorBuilder = useSensorLayerBuilder();

  const { data: sensorData } = useSensorsFromSensorsSuspenseQuery({
    variables: { input: { areaId: area.id } },
  });
  const sensors = sensorData.Sensors_sensors.items;

  const { data: journalData } = useSensorJournalsFromSensorsSuspenseQuery({
    variables: { input: { areaId: area.id } },
  });
  const journals = journalData.Sensors_sensorJournals.items;

  const sensorLayer = React.useMemo(
    () => sensorBuilder(sensors, journals),
    [sensors, journals, sensorBuilder]
  );

  return (
    <MapProxy
      layers={[sensorLayer]}
      onItemClick={(item, layer) => {
        const redirectUrl = getActiveItemRedirectUrl(item, layer);
        if (redirectUrl === window.location.pathname) return;
        navigate(redirectUrl);
      }}
    />
  );
};

const getActiveItemRedirectUrl = (active: MapItem, layer: string): string => {
  switch (layer) {
    case "sensor":
      return `/sensors/sensor/${active.id}`;
    default:
      return ".";
  }
};

export default SensorsMapProxy;
