import React from "react";
import Compo, { useModal } from "@smartly-city/compo";
import Page from "src/core/components/Page";
import { useNavigate } from "react-router-dom";
import { useSensorsTranslation } from "../../utils/translation";
import SensorList from "../../containers/SensorList/SensorList";
import SensorListSkeleton from "../../containers/SensorList/SensorList.skeleton";
import { usePermissionMatch } from "src/core/contexts";
import { SensorForm } from "../../containers/SensorForm";

const SensorCollection: React.FC = () => {
  const { t } = useSensorsTranslation();
  const navigate = useNavigate();
  const modal = useModal();
  const addPermitted = usePermissionMatch("sensors.sensor.add");

  return (
    <Page permission="sensors.sensor.get">
      <Compo.Header title={t("page_sensor_collection.title")}>
        {addPermitted && (
          <Compo.Button
            filled
            icon={Compo.PlusIcon}
            onClick={() => modal.setIsOpen(true)}
          />
        )}
      </Compo.Header>
      <React.Suspense fallback={<SensorListSkeleton />}>
        <SensorList paged filtered onClick={(sensor) => navigate(sensor.id)} />
      </React.Suspense>
      {modal.isOpen &&
        modal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <SensorForm onClose={() => modal.setIsOpen(false)} />
          </React.Suspense>
        )}
    </Page>
  );
};

export default SensorCollection;
