import React from "react";
import { Outlet } from "react-router-dom";
import { LayerFocusProxy, LayerOverrideProxy } from "@smartly-city/compo-map";
import { SENSOR_LAYER_ID, useSensorLayerBuilder } from "../utils/layers";
import {
  SensorFiltersProvider,
  useSensorFilters,
} from "../contexts/SensorFilters";
import { useCurrentArea } from "src/core/contexts";
import { useSensorsFromSensorsLazyQuery } from "src/graphql/__generated__/SensorsSensors";
import { toSensorQueryFilters } from "../utils/filters";
import { useSensorJournalsFromSensorsLazyQuery } from "src/graphql/__generated__/SensorsSensorJournals";
import type { MapLayer } from "@smartly-city/compo-map";

const SensorsSensorsProxy: React.FC = () => {
  return (
    <SensorFiltersProvider>
      <SensorsSensorFiltersProxy>
        <LayerFocusProxy layerId={SENSOR_LAYER_ID}>
          <Outlet />
        </LayerFocusProxy>
      </SensorsSensorFiltersProxy>
    </SensorFiltersProvider>
  );
};

const SensorsSensorFiltersProxy: React.FC<React.PropsWithChildren> = (
  props
) => {
  const area = useCurrentArea();
  const builder = useSensorLayerBuilder();
  const { filters } = useSensorFilters();
  const [data, setData] = React.useState<MapLayer>();
  const [getSensors] = useSensorsFromSensorsLazyQuery();
  const [getSensorJournals] = useSensorJournalsFromSensorsLazyQuery();

  const updateOverride = React.useCallback(async (): Promise<void> => {
    const { data: sensorsData } = await getSensors({
      variables: {
        input: { areaId: area.id, filters: toSensorQueryFilters(filters) },
      },
    });
    const { data: journalsData } = await getSensorJournals({
      variables: {
        input: { areaId: area.id },
      },
    });
    const sensors = sensorsData?.Sensors_sensors.items ?? [];
    const journals = journalsData?.Sensors_sensorJournals.items ?? [];
    setData(builder(sensors, journals));
  }, [area.id, filters, getSensors, getSensorJournals, builder]);

  React.useEffect(() => {
    if (!filters || Object.keys(filters).length === 0) setData(undefined);
    else void updateOverride();
  }, [filters, updateOverride]);

  return (
    <LayerOverrideProxy layerId={SENSOR_LAYER_ID} data={data}>
      {props.children}
    </LayerOverrideProxy>
  );
};

export default SensorsSensorsProxy;
