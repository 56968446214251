import React from "react";
import { useFiltersUtils } from "src/core/utils/filters";
import { SensorFiltersContext } from "./SensorFiltersContext";
import type {
  SensorFiltersStatus,
  SensorFilters,
} from "./SensorFiltersContext";

export interface SensorFiltersProviderProps {
  children?: React.ReactNode;
}

const SensorFiltersProvider: React.FC<SensorFiltersProviderProps> = (props) => {
  const initStatus: SensorFiltersStatus = {
    name: false,
    type: false,
    smartlyId: false,
    distanceUnit: false,
    communicationDate: false,
    readingsDate: false,
    signalStrength: false,
    signalToNoiseRatio: false,
    batteryLevel: false,
    distance: false,
    distancePercent: false,
  };

  const utils = useFiltersUtils<SensorFilters>({}, initStatus);

  return (
    <SensorFiltersContext.Provider
      value={{
        filters: utils.filters,
        status: utils.status,
        setFilters: utils.setFilters,
        activateFilter: utils.activateFilter,
        deactivateFilter: utils.deactivateFilter,
        clearFilters: utils.clearFilters,
      }}
    >
      {props.children}
    </SensorFiltersContext.Provider>
  );
};

export default SensorFiltersProvider;
