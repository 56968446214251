import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSensorFromSensorsMutationVariables = Types.Exact<{
  input: Types.Sensors_UpdateSensorInput;
}>;


export type UpdateSensorFromSensorsMutation = { __typename?: 'Mutation', Sensors_updateSensor: { __typename?: 'Sensors_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Sensors_GraphQLError', key: string, message: string, variables: Array<{ __typename?: 'Sensors_KeyValuePairOfStringAndString', key: string, value: string }> }> } };


export const UpdateSensorFromSensorsDocument = gql`
    mutation updateSensorFromSensors($input: Sensors_UpdateSensorInput!) {
  Sensors_updateSensor(input: $input) {
    isSuccess
    errors {
      key
      message
      variables {
        key
        value
      }
    }
    created
  }
}
    `;
export type UpdateSensorFromSensorsMutationFn = Apollo.MutationFunction<UpdateSensorFromSensorsMutation, UpdateSensorFromSensorsMutationVariables>;

/**
 * __useUpdateSensorFromSensorsMutation__
 *
 * To run a mutation, you first call `useUpdateSensorFromSensorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorFromSensorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorFromSensorsMutation, { data, loading, error }] = useUpdateSensorFromSensorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSensorFromSensorsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSensorFromSensorsMutation, UpdateSensorFromSensorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSensorFromSensorsMutation, UpdateSensorFromSensorsMutationVariables>(UpdateSensorFromSensorsDocument, options);
      }
export type UpdateSensorFromSensorsMutationHookResult = ReturnType<typeof useUpdateSensorFromSensorsMutation>;
export type UpdateSensorFromSensorsMutationResult = Apollo.MutationResult<UpdateSensorFromSensorsMutation>;
export type UpdateSensorFromSensorsMutationOptions = Apollo.BaseMutationOptions<UpdateSensorFromSensorsMutation, UpdateSensorFromSensorsMutationVariables>;