import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SensorJournalsFromSensorsQueryVariables = Types.Exact<{
  input: Types.Sensors_GetSensorJournalsInput;
}>;


export type SensorJournalsFromSensorsQuery = { __typename?: 'Query', Sensors_sensorJournals: { __typename?: 'Sensors_GetSensorJournalsPayload', count: number, total: number, items: Array<{ __typename?: 'Sensors_SensorJournalRowModel', id: any, version: number, areaId: any, sensorId: any, status: { __typename?: 'Sensors_SensorJournalStatusModel', communicationDate?: any | null, readingsDate?: any | null, signalStrength?: number | null, signalToNoiseRatio?: number | null, batteryLevel?: number | null, distanceReading?: number | null, distancePercent?: number | null, distanceUnit?: Types.Sensors_DistanceUnitModel | null } }> } };


export const SensorJournalsFromSensorsDocument = gql`
    query sensorJournalsFromSensors($input: Sensors_GetSensorJournalsInput!) {
  Sensors_sensorJournals(input: $input) {
    count
    total
    items {
      id
      version
      areaId
      sensorId
      status {
        communicationDate
        readingsDate
        signalStrength
        signalToNoiseRatio
        batteryLevel
        distanceReading
        distancePercent
        distanceUnit
      }
    }
  }
}
    `;

/**
 * __useSensorJournalsFromSensorsQuery__
 *
 * To run a query within a React component, call `useSensorJournalsFromSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorJournalsFromSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorJournalsFromSensorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSensorJournalsFromSensorsQuery(baseOptions: Apollo.QueryHookOptions<SensorJournalsFromSensorsQuery, SensorJournalsFromSensorsQueryVariables> & ({ variables: SensorJournalsFromSensorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorJournalsFromSensorsQuery, SensorJournalsFromSensorsQueryVariables>(SensorJournalsFromSensorsDocument, options);
      }
export function useSensorJournalsFromSensorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorJournalsFromSensorsQuery, SensorJournalsFromSensorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorJournalsFromSensorsQuery, SensorJournalsFromSensorsQueryVariables>(SensorJournalsFromSensorsDocument, options);
        }
export function useSensorJournalsFromSensorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SensorJournalsFromSensorsQuery, SensorJournalsFromSensorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SensorJournalsFromSensorsQuery, SensorJournalsFromSensorsQueryVariables>(SensorJournalsFromSensorsDocument, options);
        }
export type SensorJournalsFromSensorsQueryHookResult = ReturnType<typeof useSensorJournalsFromSensorsQuery>;
export type SensorJournalsFromSensorsLazyQueryHookResult = ReturnType<typeof useSensorJournalsFromSensorsLazyQuery>;
export type SensorJournalsFromSensorsSuspenseQueryHookResult = ReturnType<typeof useSensorJournalsFromSensorsSuspenseQuery>;
export type SensorJournalsFromSensorsQueryResult = Apollo.QueryResult<SensorJournalsFromSensorsQuery, SensorJournalsFromSensorsQueryVariables>;