import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateSensorJournalsIndexFromSensorsMutationVariables = Types.Exact<{
  input: Types.Sensors_Admin_ValidateSensorJournalsIndexInput;
}>;


export type ValidateSensorJournalsIndexFromSensorsMutation = { __typename?: 'Mutation', Sensors_Admin_validateSensorJournalsIndex: { __typename?: 'Sensors_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Sensors_GraphQLError', key: string, message: string, variables: Array<{ __typename?: 'Sensors_KeyValuePairOfStringAndString', key: string, value: string }> }> } };


export const ValidateSensorJournalsIndexFromSensorsDocument = gql`
    mutation validateSensorJournalsIndexFromSensors($input: Sensors_Admin_ValidateSensorJournalsIndexInput!) {
  Sensors_Admin_validateSensorJournalsIndex(input: $input) {
    isSuccess
    errors {
      key
      message
      variables {
        key
        value
      }
    }
    created
  }
}
    `;
export type ValidateSensorJournalsIndexFromSensorsMutationFn = Apollo.MutationFunction<ValidateSensorJournalsIndexFromSensorsMutation, ValidateSensorJournalsIndexFromSensorsMutationVariables>;

/**
 * __useValidateSensorJournalsIndexFromSensorsMutation__
 *
 * To run a mutation, you first call `useValidateSensorJournalsIndexFromSensorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateSensorJournalsIndexFromSensorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateSensorJournalsIndexFromSensorsMutation, { data, loading, error }] = useValidateSensorJournalsIndexFromSensorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateSensorJournalsIndexFromSensorsMutation(baseOptions?: Apollo.MutationHookOptions<ValidateSensorJournalsIndexFromSensorsMutation, ValidateSensorJournalsIndexFromSensorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateSensorJournalsIndexFromSensorsMutation, ValidateSensorJournalsIndexFromSensorsMutationVariables>(ValidateSensorJournalsIndexFromSensorsDocument, options);
      }
export type ValidateSensorJournalsIndexFromSensorsMutationHookResult = ReturnType<typeof useValidateSensorJournalsIndexFromSensorsMutation>;
export type ValidateSensorJournalsIndexFromSensorsMutationResult = Apollo.MutationResult<ValidateSensorJournalsIndexFromSensorsMutation>;
export type ValidateSensorJournalsIndexFromSensorsMutationOptions = Apollo.BaseMutationOptions<ValidateSensorJournalsIndexFromSensorsMutation, ValidateSensorJournalsIndexFromSensorsMutationVariables>;