import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSensorsTranslation } from "../../utils/translation";
import { useRemoveSensorFromSensorsMutation } from "src/graphql/__generated__/SensorsRemoveSensor";
import type { Sensors_SensorModel } from "src/graphql/types";

export interface SensorDeleteFormProps {
  onClose: () => void;
  sensor: Sensors_SensorModel;
}

const SensorDeleteForm: React.FC<SensorDeleteFormProps> = (props) => {
  const { t } = useSensorsTranslation();
  const navigate = useNavigate();

  const [deleteSensor] = useRemoveSensorFromSensorsMutation({
    variables: {
      input: {
        sensorId: props.sensor.id,
        sensorVersion: props.sensor.version,
        areaId: props.sensor.areaId,
      },
    },
    update: (cache, result) => {
      if (result.errors || !result.data?.Sensors_removeSensor.isSuccess) return;
      cache.evict({
        fieldName: "Sensors_sensorById",
      });
      cache.evict({
        fieldName: "Sensors_sensorsPaged",
      });
      cache.evict({
        fieldName: "Sensors_sensors",
      });
      cache.gc();
    },
  });

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      const { data } = await deleteSensor();
      const result = data?.Sensors_removeSensor;

      if (result?.isSuccess) {
        toast.success(t(`notification.sensor_removed`));
        props.onClose();
        navigate("..", { replace: true });
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        header={<Compo.Header title={t("form_sensor_delete.title")} />}
        buttons={
          <React.Fragment>
            <Compo.Button
              filled
              disabled={formik.isSubmitting}
              type="submit"
              variant="alert"
            >
              {t("delete")}
            </Compo.Button>
            <Compo.Button
              disabled={formik.isSubmitting}
              onClick={props.onClose}
            >
              {t("cancel")}
            </Compo.Button>
          </React.Fragment>
        }
      >
        <Text>
          {t("form_sensor_delete.text", {
            sensor: props.sensor,
          })}
        </Text>
      </Compo.ModalBox>
    </form>
  );
};

const Text = styled.span`
  margin: 1rem;
  display: inline-block;
`;

export default SensorDeleteForm;
