import React from "react";
import styled from "styled-components";
import Compo, { useDialog, useModal } from "@smartly-city/compo";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSensorsTranslation } from "../../../../utils/translation";
import { useSensorJournalsFromSensorsSuspenseQuery } from "src/graphql/__generated__/SensorsSensorJournals";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import type { Sensors_SensorModel } from "src/graphql/types";
import { SensorDeleteForm } from "src/modules/sensors/containers/SensorDeleteForm";
import { isEmpty } from "src/core/utils/common";
import { SensorForm } from "src/modules/sensors/containers/SensorForm";

export interface SensorDetailsTabProps {
  sensor: Sensors_SensorModel;
}

const SensorDetailsTab: React.FC<SensorDetailsTabProps> = (props) => {
  const { t } = useSensorsTranslation();
  const area = useCurrentArea();
  const navigate = useNavigate();
  const editModal = useModal();
  const deleteDialog = useDialog();
  const editPermitted = usePermissionMatch("sensors.sensor.update");
  const removePermitted = usePermissionMatch("sensors.sensor.remove");

  const sensor = props.sensor;
  const coordinatesString = `${sensor.location.latitude}, ${sensor.location.longitude}`;

  const { data: journalData } = useSensorJournalsFromSensorsSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        filters: {
          ofSensorId: sensor.id,
        },
      },
    },
  });
  const journal = journalData.Sensors_sensorJournals.items[0];

  if (!journal) {
    navigate("/404");
    return <div />;
  }

  const distanceUnit = !isEmpty(journal.status.distanceUnit)
    ? t("distance_unit_abbr." + journal.status.distanceUnit?.toLowerCase())
    : "";

  const toLocaleDate = (date: string): string => {
    return new Date(date).toLocaleString();
  };

  return (
    <React.Fragment>
      <Compo.Header title={sensor.name}>
        {editPermitted && (
          <Compo.Button
            outline
            variant="default"
            icon={Compo.PencilIcon}
            onClick={() => editModal.setIsOpen(true)}
          />
        )}
        {removePermitted && (
          <Compo.Button
            outline
            variant="alert"
            icon={Compo.TrashIcon}
            onClick={() => deleteDialog.setIsOpen(true)}
          />
        )}
      </Compo.Header>
      <Compo.Header type="h2" title={t("location")} />
      <Compo.LinkTile
        wide
        text={coordinatesString}
        icon={Compo.DocumentDuplicateIcon}
        onClick={async () => {
          await navigator.clipboard.writeText(coordinatesString);
          toast.success(t("notification.coordinates_copied"));
        }}
      />
      <Separator />
      <Compo.Header outline type="h2" title={t("details")} />
      <Compo.ListRow
        wide
        description={`${t("sensor_type." + sensor.type.toLowerCase())}`}
      >
        {t("page_sensor_view.type")}
      </Compo.ListRow>
      <Compo.ListRow wide description={sensor.smartlyId}>
        {t("page_sensor_view.smartly_id")}
      </Compo.ListRow>
      <Separator />
      {(!isEmpty(journal.status.communicationDate) ||
        !isEmpty(journal.status.readingsDate)) && (
        <Compo.Header outline type="h2" title={t("status")} />
      )}
      {!isEmpty(journal.status.communicationDate) && (
        <Compo.ListRow
          wide
          description={toLocaleDate(journal.status.communicationDate)}
        >
          {t("page_sensor_view.communication_date")}
        </Compo.ListRow>
      )}
      {!isEmpty(journal.status.readingsDate) && (
        <Compo.ListRow
          wide
          description={toLocaleDate(journal.status.readingsDate)}
        >
          {t("page_sensor_view.readings_date")}
        </Compo.ListRow>
      )}
      {!isEmpty(journal.status.batteryLevel) && (
        <Compo.ListRow
          wide
          description={`${journal.status.batteryLevel.toString()} %`}
        >
          {t("page_sensor_view.battery_level")}
        </Compo.ListRow>
      )}
      {!isEmpty(journal.status.distanceReading) && (
        <React.Fragment>
          <Separator />
          <Compo.Header
            outline
            type="h2"
            title={t("page_sensor_view.distance_reading")}
          />
          <Compo.ListRow
            wide
            description={
              journal.status.distanceReading.toString() + " " + distanceUnit
            }
          >
            {t("page_sensor_view.distance_reading_value")}
          </Compo.ListRow>
          {!isEmpty(journal.status.distancePercent) && (
            <Compo.ListRow
              wide
              buttons={
                <Compo.Chip
                  variant={
                    journal.status.distancePercent > 80
                      ? "alert"
                      : journal.status.distancePercent > 50
                      ? "default"
                      : "success"
                  }
                  text={`${journal.status.distancePercent.toString()} %`}
                />
              }
            >
              {t("page_sensor_view.distance_reading_percentage")}
            </Compo.ListRow>
          )}
        </React.Fragment>
      )}
      {editModal.isOpen &&
        editModal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <SensorForm
              sensor={sensor}
              onClose={() => editModal.setIsOpen(false)}
            />
          </React.Suspense>
        )}
      {deleteDialog.isOpen &&
        deleteDialog.withDialogWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <SensorDeleteForm
              sensor={sensor}
              onClose={() => deleteDialog.setIsOpen(false)}
            />
          </React.Suspense>
        )}
    </React.Fragment>
  );
};

const Separator = styled.div`
  width: 100%;
  height: 1rem;
`;

export default SensorDetailsTab;
