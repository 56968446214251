import React from "react";
import Compo from "@smartly-city/compo";
import Page from "src/core/components/Page";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useSensorsTranslation } from "../../utils/translation";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { useIndexSensorsFromSensorsMutation } from "src/graphql/__generated__/SensorsIndexSensors";
import { useIndexSensorJournalsFromSensorsMutation } from "src/graphql/__generated__/SensorsIndexSensorJournals";
import { useValidateSensorsIndexFromSensorsMutation } from "src/graphql/__generated__/SensorsValidateSensorsIndex";
import { useValidateSensorJournalsIndexFromSensorsMutation } from "src/graphql/__generated__/SensorsValidateSensorJournalsIndex";

const Administration: React.FC = () => {
  const { t } = useSensorsTranslation();
  const area = useCurrentArea();
  const [tab, setTab] = React.useState(0);
  const [submitting, setSubmitting] = React.useState(false);

  const hasValidateSensorsIndexPermission = usePermissionMatch(
    "sensors.admin.validate_sensors_index"
  );
  const [validateSensors] = useValidateSensorsIndexFromSensorsMutation({
    variables: { input: { areaId: area.id } },
  });
  const handleValidateSensors = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validateSensors();
    if (result.data?.Sensors_Admin_validateSensorsIndex.isSuccess) {
      toast.success(t(`notification.sensors_index_validated`));
    } else {
      result.data?.Sensors_Admin_validateSensorsIndex.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasValidateSensorJournalsIndexPermission = usePermissionMatch(
    "sensors.admin.validate_sensor_journals_index"
  );
  const [validateSensorJournals] =
    useValidateSensorJournalsIndexFromSensorsMutation({
      variables: { input: { areaId: area.id } },
    });
  const handleValidateSensorJournals = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validateSensorJournals();
    if (result.data?.Sensors_Admin_validateSensorJournalsIndex.isSuccess) {
      toast.success(t(`notification.sensor_journals_index_validated`));
    } else {
      result.data?.Sensors_Admin_validateSensorJournalsIndex.errors.map(
        (error) => toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexSensorsPermission = usePermissionMatch(
    "sensors.admin.index_sensors"
  );
  const [indexSensors] = useIndexSensorsFromSensorsMutation({
    variables: { input: { areaId: area.id } },
  });
  const handleIndexSensors = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexSensors();
    if (result.data?.Sensors_Admin_indexSensors.isSuccess) {
      toast.success(t(`notification.sensors_indexed`));
    } else {
      result.data?.Sensors_Admin_indexSensors.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexSensorJournalsPermission = usePermissionMatch(
    "sensors.admin.index_sensor_journals"
  );
  const [indexSensorJournals] = useIndexSensorJournalsFromSensorsMutation({
    variables: { input: { areaId: area.id } },
  });
  const handleIndexSensorJournals = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexSensorJournals();
    if (result.data?.Sensors_Admin_indexSensorJournals.isSuccess) {
      toast.success(t(`notification.sensor_journals_indexed`));
    } else {
      result.data?.Sensors_Admin_indexSensorJournals.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  return (
    <Page wide permission="sensors.admin.get">
      <Compo.Tabs
        wide
        current={tab}
        onChange={(index) => setTab(index)}
        tabs={[
          {
            icon: Compo.SearchIcon,
            content: (
              <Wrapper>
                <Compo.Header title={t("administration")} />
                <Compo.Button
                  small
                  outline
                  disabled={submitting || !hasValidateSensorsIndexPermission}
                  onClick={handleValidateSensors}
                >
                  {t("page_administration.validate_sensors_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  disabled={
                    submitting || !hasValidateSensorJournalsIndexPermission
                  }
                  onClick={handleValidateSensorJournals}
                >
                  {t("page_administration.validate_sensor_journals_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasIndexSensorsPermission}
                  onClick={handleIndexSensors}
                >
                  {t("page_administration.index_sensors")}
                </Compo.Button>

                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasIndexSensorJournalsPermission}
                  onClick={handleIndexSensorJournals}
                >
                  {t("page_administration.index_sensor_journals")}
                </Compo.Button>
              </Wrapper>
            ),
          },
        ]}
      />
    </Page>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1.5rem;
`;

export default Administration;
