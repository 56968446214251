import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndexSensorsFromSensorsMutationVariables = Types.Exact<{
  input: Types.Sensors_Admin_IndexSensorsInput;
}>;


export type IndexSensorsFromSensorsMutation = { __typename?: 'Mutation', Sensors_Admin_indexSensors: { __typename?: 'Sensors_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Sensors_GraphQLError', key: string, message: string, variables: Array<{ __typename?: 'Sensors_KeyValuePairOfStringAndString', key: string, value: string }> }> } };


export const IndexSensorsFromSensorsDocument = gql`
    mutation indexSensorsFromSensors($input: Sensors_Admin_IndexSensorsInput!) {
  Sensors_Admin_indexSensors(input: $input) {
    isSuccess
    errors {
      key
      message
      variables {
        key
        value
      }
    }
    created
  }
}
    `;
export type IndexSensorsFromSensorsMutationFn = Apollo.MutationFunction<IndexSensorsFromSensorsMutation, IndexSensorsFromSensorsMutationVariables>;

/**
 * __useIndexSensorsFromSensorsMutation__
 *
 * To run a mutation, you first call `useIndexSensorsFromSensorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndexSensorsFromSensorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indexSensorsFromSensorsMutation, { data, loading, error }] = useIndexSensorsFromSensorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIndexSensorsFromSensorsMutation(baseOptions?: Apollo.MutationHookOptions<IndexSensorsFromSensorsMutation, IndexSensorsFromSensorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndexSensorsFromSensorsMutation, IndexSensorsFromSensorsMutationVariables>(IndexSensorsFromSensorsDocument, options);
      }
export type IndexSensorsFromSensorsMutationHookResult = ReturnType<typeof useIndexSensorsFromSensorsMutation>;
export type IndexSensorsFromSensorsMutationResult = Apollo.MutationResult<IndexSensorsFromSensorsMutation>;
export type IndexSensorsFromSensorsMutationOptions = Apollo.BaseMutationOptions<IndexSensorsFromSensorsMutation, IndexSensorsFromSensorsMutationVariables>;