import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SensorsFromSensorsQueryVariables = Types.Exact<{
  input: Types.Sensors_GetSensorsInput;
}>;


export type SensorsFromSensorsQuery = { __typename?: 'Query', Sensors_sensors: { __typename?: 'Sensors_GetSensorsPayload', count: number, total: number, items: Array<{ __typename?: 'Sensors_SensorRowModel', id: any, areaId: any, type: Types.Sensors_SensorTypeModel, name: string, location: { __typename?: 'Sensors_CoordinatesModel', latitude: number, longitude: number, hash?: string | null } }> } };


export const SensorsFromSensorsDocument = gql`
    query sensorsFromSensors($input: Sensors_GetSensorsInput!) {
  Sensors_sensors(input: $input) {
    count
    total
    items {
      id
      areaId
      type
      name
      location {
        latitude
        longitude
        hash
      }
    }
  }
}
    `;

/**
 * __useSensorsFromSensorsQuery__
 *
 * To run a query within a React component, call `useSensorsFromSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorsFromSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorsFromSensorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSensorsFromSensorsQuery(baseOptions: Apollo.QueryHookOptions<SensorsFromSensorsQuery, SensorsFromSensorsQueryVariables> & ({ variables: SensorsFromSensorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorsFromSensorsQuery, SensorsFromSensorsQueryVariables>(SensorsFromSensorsDocument, options);
      }
export function useSensorsFromSensorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorsFromSensorsQuery, SensorsFromSensorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorsFromSensorsQuery, SensorsFromSensorsQueryVariables>(SensorsFromSensorsDocument, options);
        }
export function useSensorsFromSensorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SensorsFromSensorsQuery, SensorsFromSensorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SensorsFromSensorsQuery, SensorsFromSensorsQueryVariables>(SensorsFromSensorsDocument, options);
        }
export type SensorsFromSensorsQueryHookResult = ReturnType<typeof useSensorsFromSensorsQuery>;
export type SensorsFromSensorsLazyQueryHookResult = ReturnType<typeof useSensorsFromSensorsLazyQuery>;
export type SensorsFromSensorsSuspenseQueryHookResult = ReturnType<typeof useSensorsFromSensorsSuspenseQuery>;
export type SensorsFromSensorsQueryResult = Apollo.QueryResult<SensorsFromSensorsQuery, SensorsFromSensorsQueryVariables>;