import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import SensorDistanceJournalTabDailyChartSkeleton from "./charts/SensorDistanceJournalTabDailyChart.skeleton";
import SensorDistanceJournalTabMonthlyChartSkeleton from "./charts/SensorDistanceJournalTabMonthlyChart.skeleton";

const SensorDistanceJournalTabSkeleton: React.FC = () => {
  return (
    <Wrapper>
      <Compo.HeaderSkeleton />
      <SensorDistanceJournalTabDailyChartSkeleton />
      <SensorDistanceJournalTabMonthlyChartSkeleton />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default SensorDistanceJournalTabSkeleton;
