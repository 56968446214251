import React from "react";
import Compo, { type Types } from "@smartly-city/compo";
import styled from "styled-components";
import Page from "src/core/components/Page";
import SensorViewSkeleton from "./SensorView.skeleton";
import { useCurrentArea } from "src/core/contexts";
import { Navigate, useParams } from "react-router-dom";
import { SensorDetailsTab } from "./elements/SensorDetailsTab";
import { useSensorByIdFromSensorsSuspenseQuery } from "src/graphql/__generated__/SensorsSensorById";
import { useSensorJournalBySensorIdFromSensorsSuspenseQuery } from "src/graphql/__generated__/SensorsSensorJournalBySensorId";
import {
  SensorDistanceJournalTab,
  SensorDistanceJournalTabSkeleton,
} from "./elements/SensorDistanceJournalTab";
import type {
  Sensors_SensorJournalModel,
  Sensors_SensorModel,
} from "src/graphql/types";
import { isEmpty } from "src/core/utils/common";
const SensorView: React.FC = () => (
  <Page wide permission="sensors.sensor.get">
    <React.Suspense fallback={<SensorViewSkeleton />}>
      <SensorViewContent />
    </React.Suspense>
  </Page>
);

const SensorViewContent: React.FC = () => {
  const area = useCurrentArea();
  const params = useParams<{ id: string }>();
  const [current, setCurrent] = React.useState(0);

  const { data: sensorData } = useSensorByIdFromSensorsSuspenseQuery({
    variables: { input: { areaId: area.id, sensorId: params.id as string } },
  });
  const sensor = sensorData.Sensors_sensorById.sensor as Sensors_SensorModel;

  const { data: journalData } =
    useSensorJournalBySensorIdFromSensorsSuspenseQuery({
      variables: {
        input: {
          areaId: area.id,
          sensorId: sensor.id,
        },
      },
    });

  const journal = journalData?.Sensors_sensorJournalBySensorId
    ?.journal as Sensors_SensorJournalModel;

  if (!sensor || !journal) {
    return <Navigate replace to="/404" />;
  }

  const tabs: Record<string, Types.TabProps> = {
    details: {
      icon: Compo.InformationCircleIcon,
      content: <SensorDetailsTab sensor={sensor} />,
    },
  };

  if (!isEmpty(journal.status.distancePercent)) {
    tabs.distanceJournal = {
      icon: Compo.MeterIcon,
      content: (
        <React.Suspense fallback={<SensorDistanceJournalTabSkeleton />}>
          <SensorDistanceJournalTab journal={journal} />
        </React.Suspense>
      ),
    };
  }

  return (
    <Wrapper>
      <Compo.Tabs
        current={current}
        onChange={setCurrent}
        wide
        tabs={Object.values(tabs)}
      >
        {(content) => <ContentWrapper>{content}</ContentWrapper>}
      </Compo.Tabs>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  padding: 1.5rem;
`;

export default SensorView;
