import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import AddressInput from "src/core/components/AddressInput";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useCurrentArea } from "src/core/contexts";
import { useSensorsTranslation } from "../../utils/translation";
import { useAddSensorFromSensorsMutation } from "src/graphql/__generated__/SensorsAddSensor";
import {
  getFieldError,
  setFloatFieldValue,
  setIntFieldValue,
  setTextFieldValue,
  toStringValue,
} from "src/core/utils/formik";
import {
  Sensors_DistanceUnitModel,
  Sensors_SensorTypeModel,
} from "src/graphql/types";
import type {
  Sensors_SensorBoundariesModelInput,
  Sensors_SensorModel,
  Sensors_SensorUnitsModelInput,
  Sensors_UpdateSensorInput,
} from "src/graphql/types";
import { useUpdateSensorFromSensorsMutation } from "src/graphql/__generated__/SensorsUpdateSensor";

export interface SensorFormProps {
  onClose: () => void;
  sensor?: Sensors_SensorModel;
}

export interface SensorFormContext {
  name: string;
  type: Sensors_SensorTypeModel;
  smartlyId: string;
  latitude: number;
  longitude: number;
  distanceUnit?: Sensors_DistanceUnitModel;
  boundaryMinValue?: number;
  boundaryMaxValue?: number;
}

const SensorForm: React.FC<SensorFormProps> = (props) => {
  const { t } = useSensorsTranslation();
  const area = useCurrentArea();
  const navigate = useNavigate();

  const [add] = useAddSensorFromSensorsMutation({
    update: (cache, result) => {
      if (result.errors || !result.data?.Sensors_addSensor.isSuccess) return;
      cache.evict({
        fieldName: "Sensors_sensors",
      });
      cache.evict({
        fieldName: "Sensors_sensorsPaged",
      });
      cache.gc();
    },
  });

  const [update] = useUpdateSensorFromSensorsMutation({
    update: (cache, result) => {
      if (result.errors || !result.data?.Sensors_updateSensor.isSuccess) return;
      cache.evict({
        fieldName: "Sensors_sensors",
      });
      cache.evict({
        fieldName: "Sensors_sensorById",
      });
      cache.evict({
        fieldName: "Sensors_sensorsPaged",
      });
      cache.gc();
    },
  });

  const handleSubmit = async (values: SensorFormContext): Promise<void> => {
    const unitsModel: Sensors_SensorUnitsModelInput = {
      distanceUnit: values.distanceUnit,
    };

    const boundariesModel: Sensors_SensorBoundariesModelInput = {
      minValue: values.boundaryMinValue ?? 0,
      maxValue: values.boundaryMaxValue ?? 0,
    };

    if (props.sensor) {
      const newSensor = {
        sensorId: props.sensor.id,
        sensorVersion: props.sensor.version,
        areaId: props.sensor.areaId,
        name: values.name,
        smartlyId: values.smartlyId,
        location: {
          latitude: values.latitude,
          longitude: values.longitude,
        },
        units: Object.values(unitsModel).some((v) => v) ? unitsModel : null,
        boundaries: Object.values(boundariesModel).some((v) => v)
          ? boundariesModel
          : null,
      };

      const { data } = await update({
        variables: {
          input: newSensor as Sensors_UpdateSensorInput,
        },
      });

      const result = data?.Sensors_updateSensor;
      if (result?.isSuccess) {
        toast.success(t(`notification.sensor_updated`));
        props.onClose();
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    } else {
      const { data } = await add({
        variables: {
          input: {
            areaId: area.id,
            type: values.type,
            name: values.name,
            smartlyId: values.smartlyId,
            location: {
              latitude: values.latitude,
              longitude: values.longitude,
            },
            units: Object.values(unitsModel).some((v) => v) ? unitsModel : null,
            boundaries: Object.values(boundariesModel).some((v) => v)
              ? boundariesModel
              : null,
          },
        },
      });

      const result = data?.Sensors_addSensor;
      if (result?.isSuccess) {
        toast.success(t(`notification.sensor_added`));
        navigate(`/sensors/sensor/${result.value?.id as string}`);
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    }
  };

  const formik = useFormik<SensorFormContext>({
    initialValues: props.sensor
      ? {
          name: props.sensor.name,
          type: props.sensor.type,
          smartlyId: props.sensor.smartlyId,
          latitude: props.sensor.location.latitude,
          longitude: props.sensor.location.longitude,
          distanceUnit: props.sensor.units?.distanceUnit,
          boundaryMinValue: props.sensor.boundaries?.minValue,
          boundaryMaxValue: props.sensor.boundaries?.maxValue,
        }
      : {
          name: null as any,
          type: null as any,
          smartlyId: null as any,
          latitude: null as any,
          longitude: null as any,
          distanceUnit: null as any,
          boundaryMinValue: null as any,
          boundaryMaxValue: null as any,
        },
    validationSchema: yup.object().shape({
      name: yup.string().fromFormik().required(),
      type: yup
        .string()
        .fromFormik()
        .oneOf(Object.values(Sensors_SensorTypeModel))
        .required(),
      smartlyId: yup.string().fromFormik().min(11).max(11).required(),
      latitude: yup.number().fromFormik().min(-90).max(90).required(),
      longitude: yup.number().fromFormik().min(-180).max(180).required(),
      distanceUnit: yup
        .string()
        .fromFormik()
        .oneOf(Object.values(Sensors_DistanceUnitModel))
        .optional(),
      boundaryMinValue: yup.number().fromFormik().optional(),
      boundaryMaxValue: yup.number().fromFormik().optional(),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        size="lg"
        header={
          <Compo.Header
            title={t(
              "form_sensor." + (props.sensor ? "edit_title" : "add_title")
            )}
          />
        }
        buttons={
          <React.Fragment>
            <Compo.Button filled type="submit" disabled={formik.isSubmitting}>
              {t("submit")}
            </Compo.Button>
            <Compo.Button onClick={props.onClose}>{t("cancel")}</Compo.Button>
          </React.Fragment>
        }
      >
        <InputsWrapper>
          <Compo.TextInput
            wide
            name="name"
            disabled={formik.isSubmitting}
            error={formik.touched.name && formik.errors.name}
            label={t("form_sensor.name")}
            value={toStringValue(formik.values.name)}
            onChange={setTextFieldValue(formik, "name")}
          />
          <Compo.SelectInput
            wide
            name="type"
            disabled={!!props.sensor || formik.isSubmitting}
            error={formik.touched.type && formik.errors.type}
            label={t("form_sensor.type")}
            value={toStringValue(formik.values.type)}
            onChange={setTextFieldValue(formik, "type")}
          >
            <option value="" />
            {Object.values(Sensors_SensorTypeModel).map((type: string) => (
              <option key={type} value={type}>
                {t(`sensor_type.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
          <Compo.TextInput
            wide
            name="smartly-id"
            disabled={formik.isSubmitting}
            error={formik.touched.smartlyId && formik.errors.smartlyId}
            label={t("form_sensor.smartly_id")}
            value={toStringValue(formik.values.smartlyId)}
            onChange={setTextFieldValue(formik, "smartlyId")}
          />
          <AddressInput
            disabled={formik.isSubmitting}
            latitude={{
              value: toStringValue(formik.values.latitude),
              error: getFieldError(formik, "latitude"),
              onChange: setFloatFieldValue(formik, "latitude"),
            }}
            longitude={{
              value: toStringValue(formik.values.longitude),
              error: getFieldError(formik, "longitude"),
              onChange: setFloatFieldValue(formik, "longitude"),
            }}
          />
          <Compo.Header type="h2" title={t("form_sensor.units")} />
          <Compo.SelectInput
            wide
            name="distance-unit"
            disabled={formik.isSubmitting}
            error={formik.touched.distanceUnit && formik.errors.distanceUnit}
            label={t("form_sensor.distance_unit")}
            value={toStringValue(formik.values.distanceUnit)}
            onChange={setTextFieldValue(formik, "distanceUnit")}
          >
            <option value="" />
            {Object.values(Sensors_DistanceUnitModel).map((type: string) => (
              <option key={type} value={type}>
                {t(`distance_unit.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
          <Compo.Header type="h2" title={t("form_sensor.boundaries")} />
          <Compo.TextInput
            wide
            name="boundary-min-value"
            disabled={formik.isSubmitting}
            error={
              formik.touched.boundaryMinValue && formik.errors.boundaryMinValue
            }
            label={t("form_sensor.boundary_min_value")}
            value={toStringValue(formik.values.boundaryMinValue)}
            onChange={setIntFieldValue(formik, "boundaryMinValue")}
          />
          <Compo.TextInput
            wide
            name="boundary-max-value"
            disabled={formik.isSubmitting}
            error={
              formik.touched.boundaryMaxValue && formik.errors.boundaryMaxValue
            }
            label={t("form_sensor.boundary_max_value")}
            value={toStringValue(formik.values.boundaryMaxValue)}
            onChange={setIntFieldValue(formik, "boundaryMaxValue")}
          />
        </InputsWrapper>
      </Compo.ModalBox>
    </form>
  );
};

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default SensorForm;
