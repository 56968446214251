import React from "react";
import type {
  Sensors_DistanceUnitModel,
  Sensors_SensorTypeModel,
} from "src/graphql/types";

export interface SensorFilters {
  name?: string;
  type?: Sensors_SensorTypeModel;
  smartlyId?: string;
  distanceUnit?: Sensors_DistanceUnitModel;
  communicationDate?: {
    min?: Date;
    max?: Date;
  };
  readingsDate?: {
    min?: Date;
    max?: Date;
  };
  signalStrength?: {
    min?: number;
    max?: number;
  };
  signalToNoiseRatio?: {
    min?: number;
    max?: number;
  };
  batteryLevel?: {
    min?: number;
    max?: number;
  };
  distance?: {
    min?: number;
    max?: number;
  };
  distancePercent?: {
    min?: number;
    max?: number;
  };
}

export type SensorFiltersStatus = Record<keyof SensorFilters, boolean>;

export interface SensorFiltersContextState {
  filters: SensorFilters;
  status: SensorFiltersStatus;
  setFilters: (filters: SensorFilters) => void;
  deactivateFilter: (name: keyof SensorFilters) => void;
  activateFilter: (name: keyof SensorFilters) => void;
  clearFilters: () => void;
}

export const SensorFiltersContext = React.createContext<
  SensorFiltersContextState | undefined
>(undefined);

export const useSensorFilters = (): SensorFiltersContextState => {
  const context = React.useContext(SensorFiltersContext);
  return context as SensorFiltersContextState;
};
