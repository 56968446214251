import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SensorJournalBySensorIdFromSensorsQueryVariables = Types.Exact<{
  input: Types.Sensors_GetSensorJournalBySensorIdInput;
}>;


export type SensorJournalBySensorIdFromSensorsQuery = { __typename?: 'Query', Sensors_sensorJournalBySensorId: { __typename?: 'Sensors_GetSensorJournalBySensorIdPayload', journal?: { __typename?: 'Sensors_SensorJournalModel', id: any, version: number, created: any, changed: any, areaId: any, sensorId: any, status: { __typename?: 'Sensors_SensorJournalStatusModel', communicationDate?: any | null, readingsDate?: any | null, signalStrength?: number | null, signalToNoiseRatio?: number | null, batteryLevel?: number | null, distanceReading?: number | null, distancePercent?: number | null, distanceUnit?: Types.Sensors_DistanceUnitModel | null }, dailySummary: { __typename?: 'Sensors_SensorJournalSummaryModel', id: any, range: Types.Sensors_SensorJournalSummaryRangeModel, items: Array<{ __typename?: 'Sensors_SensorJournalSummaryItemModel', id: any, date: any, type: Types.Sensors_SensorJournalSummaryItemTypeModel, distance?: { __typename?: 'Sensors_SensorJournalSummaryItemDistanceSectionModel', minDistance?: number | null, maxDistance?: number | null, minDistancePercent?: number | null, maxDistancePercent?: number | null, distanceUnit?: Types.Sensors_DistanceUnitModel | null } | null }> }, monthlySummary: { __typename?: 'Sensors_SensorJournalSummaryModel', id: any, range: Types.Sensors_SensorJournalSummaryRangeModel, items: Array<{ __typename?: 'Sensors_SensorJournalSummaryItemModel', id: any, date: any, type: Types.Sensors_SensorJournalSummaryItemTypeModel, distance?: { __typename?: 'Sensors_SensorJournalSummaryItemDistanceSectionModel', minDistance?: number | null, maxDistance?: number | null, minDistancePercent?: number | null, maxDistancePercent?: number | null, distanceUnit?: Types.Sensors_DistanceUnitModel | null } | null }> } } | null } };


export const SensorJournalBySensorIdFromSensorsDocument = gql`
    query sensorJournalBySensorIdFromSensors($input: Sensors_GetSensorJournalBySensorIdInput!) {
  Sensors_sensorJournalBySensorId(input: $input) {
    journal {
      id
      version
      created
      changed
      areaId
      sensorId
      status {
        communicationDate
        readingsDate
        signalStrength
        signalToNoiseRatio
        batteryLevel
        distanceReading
        distancePercent
        distanceUnit
      }
      dailySummary {
        id
        range
        items {
          id
          date
          type
          distance {
            minDistance
            maxDistance
            minDistancePercent
            maxDistancePercent
            distanceUnit
          }
        }
      }
      monthlySummary {
        id
        range
        items {
          id
          date
          type
          distance {
            minDistance
            maxDistance
            minDistancePercent
            maxDistancePercent
            distanceUnit
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSensorJournalBySensorIdFromSensorsQuery__
 *
 * To run a query within a React component, call `useSensorJournalBySensorIdFromSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorJournalBySensorIdFromSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorJournalBySensorIdFromSensorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSensorJournalBySensorIdFromSensorsQuery(baseOptions: Apollo.QueryHookOptions<SensorJournalBySensorIdFromSensorsQuery, SensorJournalBySensorIdFromSensorsQueryVariables> & ({ variables: SensorJournalBySensorIdFromSensorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorJournalBySensorIdFromSensorsQuery, SensorJournalBySensorIdFromSensorsQueryVariables>(SensorJournalBySensorIdFromSensorsDocument, options);
      }
export function useSensorJournalBySensorIdFromSensorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorJournalBySensorIdFromSensorsQuery, SensorJournalBySensorIdFromSensorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorJournalBySensorIdFromSensorsQuery, SensorJournalBySensorIdFromSensorsQueryVariables>(SensorJournalBySensorIdFromSensorsDocument, options);
        }
export function useSensorJournalBySensorIdFromSensorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SensorJournalBySensorIdFromSensorsQuery, SensorJournalBySensorIdFromSensorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SensorJournalBySensorIdFromSensorsQuery, SensorJournalBySensorIdFromSensorsQueryVariables>(SensorJournalBySensorIdFromSensorsDocument, options);
        }
export type SensorJournalBySensorIdFromSensorsQueryHookResult = ReturnType<typeof useSensorJournalBySensorIdFromSensorsQuery>;
export type SensorJournalBySensorIdFromSensorsLazyQueryHookResult = ReturnType<typeof useSensorJournalBySensorIdFromSensorsLazyQuery>;
export type SensorJournalBySensorIdFromSensorsSuspenseQueryHookResult = ReturnType<typeof useSensorJournalBySensorIdFromSensorsSuspenseQuery>;
export type SensorJournalBySensorIdFromSensorsQueryResult = Apollo.QueryResult<SensorJournalBySensorIdFromSensorsQuery, SensorJournalBySensorIdFromSensorsQueryVariables>;