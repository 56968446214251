import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { useSensorsTranslation } from "src/modules/sensors/utils/translation";
import SensorDistanceJournalTabDailyChart from "./charts/SensorDistanceJournalTabDailyChart";
import SensorDistanceJournalTabMonthlyChart from "./charts/SensorDistanceJournalTabMonthlyChart";
import type { Sensors_SensorJournalModel } from "src/graphql/types";

export interface SensorDistanceJournalTabProps {
  journal: Sensors_SensorJournalModel;
}

const SensorDistanceJournalTab: React.FC<SensorDistanceJournalTabProps> = (
  props
) => {
  const { t } = useSensorsTranslation();

  return (
    <Wrapper>
      <Compo.Header type="h2" title={t("page_sensor_view.distance_journal")} />
      <SensorDistanceJournalTabDailyChart journal={props.journal} />
      <SensorDistanceJournalTabMonthlyChart journal={props.journal} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default SensorDistanceJournalTab;
